// create angular service
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { PLATFORM_ID } from "@angular/core";
import { Injectable, computed, effect, inject, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { TuiBrightness, TuiNightThemeService } from "@taiga-ui/core";

export type ThemeMode = "onSystem" | "onLight" | "onDark";

@Injectable({
  providedIn: "root"
})
export class ThemeSwitcherService {
  private document: Document = inject(DOCUMENT);
  private systemThemeIsNight = toSignal(inject(TuiNightThemeService));
  private isPlatformBrowser: boolean = isPlatformBrowser(inject(PLATFORM_ID));

  mode = signal<ThemeMode>(this.getInitialValue());
  currentTheme = computed<TuiBrightness>(() => {
    const theme = this.mode() === "onSystem" ? (this.systemThemeIsNight() ? "onDark" : "onLight") : this.mode();
    return theme as TuiBrightness;
  });

  constructor() {
    effect(() => {
      this.document.body.setAttribute("data-mode", this.currentTheme());
    });
  }

  toggleMode(mode: ThemeMode = "onLight") {
    this.mode.set(mode);
    localStorage.setItem("theme", mode);
  }

  private getInitialValue(): ThemeMode {
    if (this.isPlatformBrowser) {
      return (localStorage.getItem("theme") as ThemeMode) ?? "onLight";
    }
    return "onLight";
  }
}
